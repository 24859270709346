import React from "react";
import Outropiggy from "../strapi/structure/Outropiggy";
import Masthead from "../strapi/structure/Masthead";
function ShoppingLayout({ data = [], children, shopName = "" }) {
  return (
    <>
      {data &&
        data.map(({ node }) => (
          <React.Fragment key={node}>
            <Masthead
              mhtitle={shopName ? shopName : node.ca_mh.mh_title}
              mhsubtitle={node.ca_mh.mh_subtitle}
              mhbackground={node.ca_mh.mh_bg.publicURL}
            />
            {children}
            <Outropiggy outro={node.access_outro.piggy_outro} />
          </React.Fragment>
        ))}
    </>
  );
}

export default ShoppingLayout;
